import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  clearStorage(): void {
    const languageId = this.getLanguageID();
    localStorage.clear();
    sessionStorage.clear();
    this.addLanguageID(languageId || environment.languageId);
  }

  private _getItem(key: string): string | null {
    // Verifica se o item está no sessionStorage primeiro
    return sessionStorage.getItem(`${environment.name}_${key}`) || localStorage.getItem(`${environment.name}_${key}`);
  }

  private _addItem(key: string, value: string, useLocalStorage: boolean = false): void {
    if (useLocalStorage) {
      localStorage.setItem(`${environment.name}_${key}`, value);
    } else {
      sessionStorage.setItem(`${environment.name}_${key}`, value);
    }
  }

  private _removeItem(key: string): void {
    localStorage.removeItem(`${environment.name}_${key}`);
    sessionStorage.removeItem(`${environment.name}_${key}`);
  }

  addLanguageID(lang: string, useLocalStorage: boolean = false): void {
    this._addItem('language_id', lang, useLocalStorage);
  }

  getLanguageID(): string | null {
    return this._getItem('language_id');
  }

  addAuthToken(token: string, useLocalStorage: boolean = false): void {
    this._addItem('authToken', token, useLocalStorage);
  }

  removeAuthToken(): void {
    this._removeItem('authToken');
  }

  getAuthToken(): string | null {
    return this._getItem('authToken');
  }
}
